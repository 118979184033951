import * as React from 'react';

type Props = {
  className?: string;
  dataTestId: string;
};

const HamburgerIcon = (props: Props) => {
  const { dataTestId, className, ...otherProps } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={17}
      fill="none"
      className={className || 'fill-teal-5'}
      data-testid={dataTestId}
      {...otherProps}
    >
      <title>{'Open menu mobile Icon'}</title>
      <rect width={20} height={2.5} rx={0.5} />
      <rect width={20} height={2.5} y={7} rx={0.5} />
      <rect width={20} height={2.5} y={14} rx={0.5} />
    </svg>
  );
};

export default HamburgerIcon;
