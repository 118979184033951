import { useAuthData } from '@/hooks/useAuthData';

import UserIcon from '../icons/UserIcon';
import { DesktopMenuItem } from './DesktopMenuItem';
import { MenuItem as MenuItemType } from './constants';
import {
  SUPPLIER_ACCOUNT_MENU_ITEMS,
  SUPPLIER_USER_AUTH_MENU_ITEMS,
} from './constants';

type NuveenDesktopMenuItemsProps = {
  isSubMenuOpen: string;
  isTransparentMode: boolean;
  onKeyDown: (
    e: React.KeyboardEvent<HTMLElement>,
    menuItem: MenuItemType
  ) => void;
  onMouseEnter: (
    e: React.MouseEvent<HTMLElement>,
    menuItem: MenuItemType
  ) => void;
  onSubMenuClick: (
    menuItem: MenuItemType,
    e: React.MouseEvent<HTMLElement>
  ) => void;
  onAccountSubMenuClick: (
    menuItem: MenuItemType,
    e: React.MouseEvent<HTMLElement>
  ) => void;
};

export default function NuveenDesktopMenuItems({
  isTransparentMode,
  isSubMenuOpen,
  onKeyDown,
  onMouseEnter,
  onSubMenuClick,
  onAccountSubMenuClick,
}: NuveenDesktopMenuItemsProps) {
  const { isAuthenticated } = useAuthData();

  if (isAuthenticated)
    return (
      <DesktopMenuItem
        key={SUPPLIER_ACCOUNT_MENU_ITEMS.key}
        menuItem={SUPPLIER_ACCOUNT_MENU_ITEMS}
        isCurrentRoute={false}
        isOpen={isSubMenuOpen === SUPPLIER_ACCOUNT_MENU_ITEMS.key}
        withCaret
        className="right-0"
        isTransparentMode={isTransparentMode}
        icon={<UserIcon />}
        onKeyDown={onKeyDown}
        onMouseEnter={onMouseEnter}
        onSubMenuClick={onAccountSubMenuClick}
      />
    );

  return (
    <DesktopMenuItem
      key={SUPPLIER_USER_AUTH_MENU_ITEMS.key}
      menuItem={SUPPLIER_USER_AUTH_MENU_ITEMS}
      isCurrentRoute={false}
      isOpen={isSubMenuOpen === SUPPLIER_USER_AUTH_MENU_ITEMS.key}
      withCaret
      className="right-0"
      isTransparentMode={isTransparentMode}
      icon={<UserIcon />}
      onKeyDown={onKeyDown}
      onMouseEnter={onMouseEnter}
      onSubMenuClick={onSubMenuClick}
    />
  );
}
