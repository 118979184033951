type Props = {
  className?: string;
};

export default function NuveenLogo(props: Props) {
  const { className } = props;

  return (
    <svg
      width="125"
      height="23"
      viewBox="0 0 125 23"
      fill="none"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      className={className || 'fill-[#263746]'}
    >
      <title>Logo Nuveen</title>

      <path
        d="M22 16H12.5301V14.8468C12.747 14.8108 12.9639 14.8108 13.253 14.7748C13.5422 14.7387 13.759 14.7027 13.9036 14.6306C14.1205 14.5225 14.2651 14.3423 14.3735 14.1261C14.4819 13.9099 14.5181 13.5856 14.5181 13.1532V5.8018C14.5181 4.72072 14.3373 3.92793 13.9398 3.38739C13.5422 2.84685 12.9639 2.59459 12.2048 2.59459C11.7711 2.59459 11.4096 2.66667 11.012 2.77477C10.6506 2.88288 10.2892 3.0991 9.96385 3.31532C9.6747 3.53153 9.42169 3.74775 9.24096 3.96396C9.06024 4.18018 8.91566 4.36036 8.77108 4.54054V12.973C8.77108 13.3333 8.80723 13.6577 8.91566 13.8739C9.0241 14.1261 9.20482 14.3063 9.42169 14.4144C9.60241 14.5225 9.78313 14.5946 10.0361 14.6667C10.2892 14.7387 10.5422 14.7748 10.759 14.8108V15.964H1.21687V14.8108C1.46988 14.7748 1.75904 14.7748 2.01205 14.7387C2.26506 14.7027 2.51807 14.6667 2.6988 14.5946C3.0241 14.4865 3.27711 14.3063 3.38554 14.0541C3.49398 13.8018 3.56626 13.5135 3.56626 13.1171V4C3.56626 3.67568 3.49398 3.35135 3.31325 3.02703C3.13253 2.73874 2.91566 2.48649 2.66265 2.27027C2.48193 2.16216 2.22892 2.05405 1.93976 1.94595C1.6506 1.87387 1.3253 1.83784 1 1.8018V0.648649L8.51807 0.252252L8.73494 0.468468V2.73874H8.80723C9.09639 2.48649 9.45783 2.16216 9.85542 1.8018C10.2892 1.44144 10.6506 1.15315 11.012 0.900901C11.4096 0.648649 11.9157 0.432432 12.5301 0.252252C13.1446 0.0720721 13.8313 0 14.5542 0C16.253 0 17.5542 0.468468 18.3855 1.44144C19.253 2.41441 19.6506 3.71171 19.6506 5.2973V12.973C19.6506 13.3694 19.7229 13.6577 19.8313 13.9099C19.9398 14.1261 20.1566 14.3063 20.4819 14.4505C20.6626 14.5225 20.8434 14.5946 21.0964 14.6667C21.3494 14.7387 21.6386 14.7748 21.9277 14.8108V16H22Z"
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M45 16.1689L37.5714 16.66L37.3214 16.4333V14.28H37.2143C36.8571 14.62 36.5 14.96 36.1786 15.2622C35.8571 15.6022 35.4286 15.8667 34.9643 16.1311C34.4286 16.4333 33.9286 16.66 33.4643 16.7733C33 16.9244 32.3214 17 31.3929 17C29.8214 17 28.6429 16.5089 27.7857 15.4889C26.9286 14.4689 26.5357 13.0711 26.5357 11.2578V3.85333C26.5357 3.43778 26.4643 3.06 26.3214 2.79556C26.1786 2.53111 25.9643 2.30444 25.7143 2.07778C25.5 1.92667 25.25 1.81333 24.8929 1.73778C24.5714 1.66222 24.25 1.62444 24 1.58667V0.415556L31.5 0L31.6786 0.264444V11.22C31.6786 12.3533 31.8929 13.1844 32.2857 13.7133C32.6786 14.2422 33.25 14.5067 33.9643 14.5067C34.7143 14.5067 35.3571 14.3178 35.9643 13.9022C36.5357 13.4867 36.9643 13.0711 37.25 12.6178V3.70222C37.25 3.32444 37.1786 2.98444 37.0357 2.68222C36.8929 2.38 36.7143 2.15333 36.4286 1.96444C36.25 1.85111 35.9643 1.73778 35.6429 1.7C35.2857 1.66222 34.9286 1.62444 34.5714 1.58667V0.415556L42.1429 0L42.3929 0.264444V12.92C42.3929 13.2978 42.4643 13.6378 42.6071 13.9022C42.75 14.1667 42.9643 14.3933 43.25 14.5822C43.4286 14.6956 43.7143 14.8089 44 14.8467C44.3214 14.8844 44.6429 14.9222 44.9286 14.9222V16.1689H45Z"
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M64 1.17431C63.5383 1.24771 63.0766 1.43119 62.6505 1.72477C62.2243 2.01835 61.8336 2.45872 61.5495 3.08257C60.8393 4.62385 60.129 6.20183 59.4187 7.77982C58.7084 9.3578 58.0336 10.8991 57.3589 12.367C57.1458 12.8073 56.9327 13.3945 56.6486 14.0917C56.4 14.789 56.1514 15.4495 55.9738 16H53.1327C51.9607 13.1376 50.9308 10.789 50.0785 8.91743C49.2262 7.04587 48.3383 5.02752 47.415 2.86239C47.2019 2.34862 46.8467 1.98165 46.385 1.68807C45.9234 1.3945 45.4617 1.21101 45 1.17431V0H54.5178V1.17431C54.1626 1.17431 53.772 1.24771 53.2748 1.43119C52.8131 1.57798 52.5645 1.76147 52.5645 2.01835C52.5645 2.09174 52.6 2.16514 52.6355 2.31193C52.671 2.42202 52.7421 2.56881 52.7776 2.75229C53.0972 3.55963 53.5944 4.73394 54.2692 6.23853C54.9439 7.74312 55.6542 9.3578 56.4 11.0459C56.8972 9.87156 57.3944 8.73394 57.8916 7.63303C58.3533 6.53211 58.9215 5.10092 59.6318 3.37615C59.7383 3.11927 59.7738 2.89908 59.8093 2.75229C59.8093 2.6055 59.8449 2.49541 59.8449 2.42202C59.8449 2.23853 59.7383 2.05505 59.5607 1.90826C59.3832 1.76147 59.1701 1.65138 58.9215 1.54128C58.6729 1.43119 58.4243 1.3578 58.1757 1.2844C57.9271 1.24771 57.7495 1.21101 57.572 1.17431V0H63.9645V1.17431H64Z"
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M83 12.6201C82.6328 13.2511 82.1922 13.8079 81.6415 14.3646C81.1274 14.9214 80.5032 15.3668 79.8423 15.7751C79.1447 16.1834 78.4104 16.4803 77.676 16.7031C76.9417 16.9258 76.0972 17 75.1426 17C73.5637 17 72.2052 16.7773 71.067 16.3319C69.9287 15.8865 68.9741 15.2555 68.2397 14.5131C67.5054 13.7336 66.9546 12.8428 66.5508 11.8035C66.1836 10.7642 66 9.65065 66 8.42576C66 7.31223 66.1836 6.27293 66.5875 5.27074C66.9914 4.26856 67.5421 3.37773 68.2765 2.56114C69.0108 1.78166 69.9287 1.18777 71.0302 0.70524C72.1317 0.222707 73.3801 0 74.7387 0C76.2441 0 77.4557 0.18559 78.4104 0.556769C79.365 0.927948 80.1728 1.4476 80.797 2.0786C81.4212 2.70961 81.8618 3.41485 82.1188 4.26856C82.4125 5.12227 82.5227 5.97598 82.5227 6.90393V7.94323H71.7279C71.7279 10.1703 72.1317 11.8406 72.9762 12.9913C73.8207 14.1419 75.1426 14.7358 76.9784 14.7358C77.9698 14.7358 78.8877 14.476 79.6955 13.9192C80.5032 13.3624 81.1641 12.6572 81.7516 11.8035L83 12.6201ZM76.8683 6.45851C76.8683 5.82751 76.8315 5.15939 76.7581 4.45415C76.6847 3.78603 76.5745 3.22926 76.4276 2.78384C76.2441 2.26419 75.987 1.89301 75.6933 1.63319C75.3629 1.37336 74.9957 1.26201 74.5184 1.26201C73.7106 1.26201 73.0497 1.67031 72.5356 2.52402C72.0583 3.37773 71.7646 4.71397 71.7279 6.53275L76.8683 6.45851Z"
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M102 12.6201C101.633 13.2511 101.192 13.8079 100.641 14.3646C100.127 14.9214 99.5032 15.3668 98.8423 15.7751C98.1447 16.1834 97.4104 16.4803 96.676 16.7031C95.9417 16.9258 95.0972 17 94.1425 17C92.5637 17 91.2052 16.7773 90.067 16.3319C88.9287 15.8865 87.9741 15.2555 87.2397 14.5131C86.5054 13.7336 85.9546 12.8428 85.5508 11.8035C85.1469 10.7642 85 9.65065 85 8.42576C85 7.31223 85.1836 6.27293 85.5875 5.27074C85.9914 4.26856 86.5421 3.37773 87.2765 2.56114C88.0108 1.78166 88.9287 1.18777 90.0302 0.70524C91.1318 0.222707 92.3801 0 93.7387 0C95.2441 0 96.4557 0.18559 97.4104 0.556769C98.365 0.927948 99.1728 1.4476 99.797 2.0786C100.421 2.70961 100.862 3.41485 101.119 4.26856C101.413 5.12227 101.523 5.97598 101.523 6.90393V7.94323H90.7279C90.7279 10.1703 91.1317 11.8406 91.9762 12.9913C92.8207 14.1419 94.1425 14.7358 95.9784 14.7358C96.9698 14.7358 97.8877 14.476 98.6955 13.9192C99.5032 13.3624 100.164 12.6572 100.752 11.8035L102 12.6201ZM95.8682 6.45851C95.8682 5.82751 95.8315 5.15939 95.7581 4.45415C95.6847 3.78603 95.5745 3.22926 95.4276 2.78384C95.2441 2.26419 94.987 1.89301 94.6933 1.63319C94.3628 1.37336 93.9957 1.26201 93.5184 1.26201C92.7106 1.26201 92.0497 1.67031 91.5356 2.52402C91.0583 3.37773 90.7646 4.71397 90.7279 6.53275L95.8682 6.45851Z"
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M125 16H115.53V14.8442C115.747 14.8081 115.964 14.8081 116.253 14.772C116.542 14.7359 116.759 14.6998 116.904 14.6275C117.12 14.5192 117.265 14.3386 117.373 14.1219C117.482 13.9052 117.518 13.5801 117.518 13.1467V5.77878C117.518 4.69526 117.337 3.90068 116.94 3.35892C116.542 2.81716 115.964 2.56433 115.205 2.56433C114.771 2.56433 114.41 2.63657 114.012 2.74492C113.651 2.85327 113.289 3.06998 112.964 3.28668C112.675 3.50339 112.422 3.72009 112.241 3.93679C112.06 4.1535 111.916 4.33409 111.771 4.51467V12.9661C111.771 13.3273 111.807 13.6524 111.916 13.8691C112.024 14.1219 112.205 14.3025 112.422 14.4108C112.602 14.5192 112.783 14.5914 113.036 14.6637C113.289 14.7359 113.542 14.772 113.759 14.8081V15.9639H104.217V14.8081C104.47 14.772 104.759 14.772 105.012 14.7359C105.265 14.6998 105.518 14.6637 105.699 14.5914C106.024 14.4831 106.277 14.3025 106.386 14.0497C106.494 13.7968 106.566 13.5079 106.566 13.1106V3.97291C106.566 3.64786 106.494 3.3228 106.313 2.99774C106.133 2.67269 105.916 2.41986 105.663 2.23928C105.482 2.13093 105.229 2.02257 104.94 1.91422C104.651 1.84199 104.325 1.76975 104 1.73363V0.613995L111.518 0.216704L111.771 0.469526V2.74492H111.843C112.133 2.4921 112.494 2.16704 112.928 1.80587C113.361 1.4447 113.723 1.15576 114.084 0.902935C114.482 0.650113 114.988 0.433409 115.602 0.252822C116.217 0.0722348 116.904 0 117.627 0C119.325 0 120.627 0.469526 121.458 1.4447C122.325 2.41986 122.723 3.72009 122.723 5.30926V13.0023C122.723 13.3995 122.795 13.6885 122.904 13.9413C123.012 14.158 123.229 14.3386 123.554 14.4831C123.735 14.5553 123.916 14.6275 124.169 14.6998C124.422 14.772 124.711 14.8081 125 14.8442V16Z"
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  );
}
