import { useTranslations } from 'next-intl';

import { Link } from '../Link';
import ChevronIcon from '../icons/ChevronIcon';
import { EMPTY_URL, MenuItem } from './constants';

import clsx from 'clsx';

type MenuItemLinkProps = {
  isOpen: boolean;
  isCurrentRoute: boolean;
  menuItem: MenuItem;
  icon?: React.ReactNode;
  withCaret?: boolean;
  isTransparentMode?: boolean;
  withBorder?: boolean;
  onKeyDown: (e: React.KeyboardEvent<HTMLElement>, menuItem: MenuItem) => void;
  onMouseEnter: (e: React.MouseEvent<HTMLElement>, menuItem: MenuItem) => void;
};

export default function MenuItemLink({
  isOpen,
  isCurrentRoute,
  menuItem,
  icon,
  withCaret,
  isTransparentMode,
  withBorder,
  onKeyDown,
  onMouseEnter,
}: MenuItemLinkProps) {
  const t = useTranslations('nav');

  return (
    <Link
      className={clsx(
        'relative flex h-full items-center before:absolute before:bottom-0 before:left-0 before:right-0 no-underline',
        'before:h-1 before:bg-teal-5 before:opacity-0 before:transition-all before:duration-100 hover:before:opacity-100',
        isOpen && 'before:opacity-100',
        isCurrentRoute && 'before:opacity-100'
      )}
      href={menuItem.url || EMPTY_URL}
      rel={menuItem.isExternal ? 'noopener' : ''}
      target={menuItem.isExternal ? '_blank' : '_self'}
      onKeyDown={(e) => onKeyDown(e, menuItem)}
      onMouseEnter={(e) => onMouseEnter(e, menuItem)}
      data-testid={`nav-${menuItem.key}`}
    >
      <div
        className={clsx(
          withCaret && 'flex items-center gap-1 text-[15px]',
          withBorder &&
            'px-2 py-[2px] border rounded-full border-teal-99 border-opacity-40 font-bold',
          isTransparentMode ? 'text-white' : 'text-teal-5',
          isTransparentMode && withBorder && 'text-opacity-80'
        )}
      >
        {icon == null ? <div>{menuItem.label || t(menuItem.key)}</div> : icon}
        {withCaret && (
          <ChevronIcon
            className={clsx(
              isOpen && 'rotate-180',
              isTransparentMode ? 'stroke-white' : 'stroke-teal-5'
            )}
            strokeOpacity={isTransparentMode ? 0.8 : 1}
            isBold={true}
          />
        )}
      </div>
    </Link>
  );
}
