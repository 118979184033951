import { useTranslations } from 'next-intl';

import clsx from 'clsx';

export function NewTag(props: React.ComponentPropsWithoutRef<'span'>) {
  const { className, ...divProps } = props;
  const t = useTranslations('nav');

  return (
    <span
      className={clsx(
        'px-2 py-1 text-xs font-medium rounded-full bg-honey-60',
        className
      )}
      data-testid="new-tag"
      {...divProps}
    >
      {t('new')}
    </span>
  );
}
