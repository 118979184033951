import { useTranslations } from 'next-intl';

import { Link } from '@/components/Link';
import { useAuthData } from '@/hooks/useAuthData';
import { Claims, Scopes } from '@/lib/users/claims';
import { MEMBER_PORTAL_LOGIN_URL } from '@/utils/constants';

import { HorizontalDivider } from '../HorizontalDivider';
import MenuItemLink from './MenuItemLink';
import {
  ACCOUNT_MENU_ITEMS,
  EMPTY_URL,
  MenuItem as MenuItemType,
} from './constants';

type AccountMenuItemProps = {
  menuItem: MenuItemType;
  isOpen: boolean;
  isCurrentRoute: boolean;
  withCaret?: boolean;
  isTransparentMode?: boolean;
  withBorder?: boolean;
  icon?: React.ReactNode;
  onKeyDown: (
    e: React.KeyboardEvent<HTMLElement>,
    menuItem: MenuItemType
  ) => void;
  onMouseEnter: (
    e: React.MouseEvent<HTMLElement>,
    menuItem: MenuItemType
  ) => void;
  onSubMenuClick?: (
    menuItem: MenuItemType,
    e: React.MouseEvent<HTMLElement>
  ) => void;
};

export function AccountMenuItem({
  menuItem,
  isOpen,
  isCurrentRoute,
  withCaret,
  isTransparentMode,
  withBorder,
  icon,
  onKeyDown,
  onMouseEnter,
  onSubMenuClick,
}: AccountMenuItemProps) {
  const t = useTranslations('nav');

  const { checkClaims } = useAuthData();

  const showMemberPortalMenu = checkClaims(Scopes.ClientAccess, [
    Claims.CanAccessMemberPortal,
  ]);

  return (
    <li
      role="menuitem"
      key={menuItem.key}
      className="cursor-pointer"
      aria-haspopup={!!menuItem.subMenu}
      aria-expanded={isOpen}
    >
      <MenuItemLink
        isOpen={isOpen}
        isCurrentRoute={isCurrentRoute}
        menuItem={menuItem}
        onKeyDown={onKeyDown}
        onMouseEnter={onMouseEnter}
        icon={icon}
        withCaret={withCaret}
        isTransparentMode={isTransparentMode}
        withBorder={withBorder}
      />

      {isOpen && (
        <div className="absolute bg-white w-[205px] p-5 pt-7 rounded-sm shadow top-[62px] -z-10">
          <ul
            className="flex flex-col gap-5"
            role="menu"
            aria-orientation="vertical"
          >
            {showMemberPortalMenu && (
              <>
                <li role="menuitem">
                  <Link
                    href={MEMBER_PORTAL_LOGIN_URL}
                    rel="noopener"
                    target="_blank"
                    data-testid="nav-member-portal-link"
                  >
                    {t('member-portal')}
                  </Link>
                </li>
                <HorizontalDivider />
                <p className="text-xs font-bold uppercase">{t('on-demand')}</p>
              </>
            )}

            {ACCOUNT_MENU_ITEMS.subMenu?.map((subMenuItem) => (
              <>
                {subMenuItem.key === 'logout' && <HorizontalDivider />}
                <li key={subMenuItem.key} role="menuitem">
                  <Link
                    href={subMenuItem.url || EMPTY_URL}
                    data-testid={`nav-${subMenuItem.key}`}
                    onClick={(e) =>
                      onSubMenuClick && onSubMenuClick(subMenuItem, e)
                    }
                  >
                    {subMenuItem.label || t(subMenuItem.key)}
                  </Link>
                </li>
              </>
            ))}
          </ul>
        </div>
      )}
    </li>
  );
}
